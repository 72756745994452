import {
  Box,
  Button,
  Grid,
  Stack,
  SxProps,
  Theme,
  Typography,
} from "@mui/material";
import { ClearKey, DeleteKey, NumberKey } from "./keys";
import { useState } from "react";

interface IPhoneNumberKeypadProps {
  value?: string;
  onChange: (newVal: string) => void;
  sx?: SxProps<Theme>;
}

const PhoneNumberKeypad: React.FunctionComponent<IPhoneNumberKeypadProps> = (
  props,
) => {
  const keys = [
    {
      key: "1",
      value: "1",
    },
    {
      key: "2",
      value: "2",
    },
    {
      key: "3",
      value: "3",
    },
    {
      key: "4",
      value: "4",
    },
    {
      key: "5",
      value: "5",
    },
    {
      key: "6",
      value: "6",
    },
    {
      key: "7",
      value: "7",
    },
    {
      key: "8",
      value: "8",
    },
    {
      key: "9",
      value: "9",
    },
    {
      key: null,
      value: null,
    },
    {
      key: "0",
      value: "0",
    },
    {
      key: "Del",
      value: "Del",
    },
  ];

  function getKeyElement(value: string | null): JSX.Element {
    switch (value) {
      case null:
        return <Box sx={{ WebkitTapHighlightColor: "transparent" }} />;
      case "Del":
        return (
          <DeleteKey
            onClick={() => {
              props.onChange(props.value?.slice(0, -1) || "");
            }}
          />
        );
      default:
        return (
          <NumberKey
            value={value}
            onClick={() => {
              props.onChange(props.value + value);
            }}
          />
        );
    }
  }

  return (
    <Grid container sx={{ ...props.sx }}>
      {keys.map((el) => (
        <Grid
          item
          xs={4}
          key={el.key}
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",

            borderRadius: "10px",

            "&:active": {
              bgcolor: "transparent",
            },
            py: "4px",
            px: "2px",
          }}
        >
          {getKeyElement(el.value)}
        </Grid>
      ))}
    </Grid>
  );
};

export default PhoneNumberKeypad;
