import { Stack } from "@mui/material";

interface IKeyParentProps {
  onClick: () => void;
  children: JSX.Element | JSX.Element[];
}

const KeyParent: React.FunctionComponent<IKeyParentProps> = (props) => {
  return (
    <Stack
      onClick={props.onClick}
      sx={(theme) => ({
        bgcolor: "grey.200",
        borderRadius: "100px",
        height: "60px",
        width: "100%",
        cursor: "pointer",
        alignItems: "center",
        justifyContent: "center",

        mb: "3px",
        "&:active": {
          bgcolor: "grey.300",
        },
        WebkitTapHighlightColor: "transparent",
      })}
    >
      {props.children}
    </Stack>
  );
};

export default KeyParent;
