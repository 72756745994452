import { Box, Stack, SxProps, Theme, Typography } from "@mui/material";

import { AppContext } from "@/context/AppContext";
import { useContext } from "react";

interface IInstructionsProps {
  sx?: SxProps<Theme>;
}

const Instructions: React.FunctionComponent<IInstructionsProps> = (props) => {
  const appContext = useContext(AppContext);

  const { bodyImageUrl, title, subTitle } = appContext?.state.ui.config || {};

  return (
    <Stack
      sx={{
        alignItems: "center",
        justifyContent: "center",
        height: "100%",
        ...props.sx,
      }}
    >
      {bodyImageUrl && (
        <Box
          component="img"
          sx={{
            height: "275px",
            width: "275px",
            borderRadius: "200px",
          }}
          src={bodyImageUrl}
        />
      )}

      <Typography
        variant="h2"
        sx={{
          textAlign: "center",
          mx: "5%",
          mt: 5,
          whiteSpace: "pre-line",
        }}
      >
        {title}
      </Typography>

      <Typography
        variant="h4"
        sx={{
          textAlign: "center",
          mx: "3%",
          fontWeight: 400,
          mt: 4,
          color: "text.secondary",
          lineHeight: "2rem",
          whiteSpace: "pre-line",
        }}
      >
        {subTitle}
      </Typography>
    </Stack>
  );
};

export default Instructions;
