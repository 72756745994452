import { SxProps, Theme, Typography } from "@mui/material";

import AppButton from "@/components/buttons/AppButton";
import { useAddVisit } from "@/hooks/useAddVisit";

interface ISubmitButtonProps {
  sx?: SxProps<Theme>;
}

const SubmitButton: React.FC<ISubmitButtonProps> = (props) => {
  const { submit } = useAddVisit();

  return (
    <AppButton onClick={submit} sx={{ height: "56px", p: "0 36px" }}>
      <Typography
        sx={{
          fontSize: "21px",
          lineHeight: "22px",
          letterSpacing: "0.21px",
        }}
      >
        Submit Phone Number
      </Typography>
    </AppButton>
  );
};

export default SubmitButton;
