import { useContext } from "react";
import { Radio, Stack, Typography } from "@mui/material";

import CountDownModal from "@/components/modals/CountDownModal";
import { Modals } from "../types";
import { AppContext } from "@/context/AppContext";

import { useConfigureLocation } from "@/hooks/useConfigureLocation";
import AppButton from "@/components/buttons/AppButton";

interface ILocationPickerModalProps {}

const LocationPickerModal: React.FC<ILocationPickerModalProps> = (props) => {
  const {
    selectedLocationID,
    setLocationID,
    isPickerOpen,
    locations,
    closePicker,
  } = useConfigureLocation();

  if (!locations || !Array.isArray(locations)) {
    return <></>;
  }

  return (
    <CountDownModal
      open={isPickerOpen}
      sx={{ width: "70vw", alignItems: "flex-start" }}
    >
      <Typography variant="h2">Select Store Location</Typography>

      <Stack
        spacing={1}
        sx={{
          my: 2,
          maxHeight: "70vh",
          overflowY: "auto",

          width: "100%",
        }}
      >
        {locations
          ?.sort((a, b) => a.name.localeCompare(b.name))
          .map((el) => {
            return (
              <Stack
                direction="row"
                key={el.ID}
                onClick={() => {
                  setLocationID(el.ID);
                }}
                sx={{
                  border: (theme) => `1px solid ${theme.palette.text.primary}`,
                  borderRadius: "10px",
                  alignItems: "center",
                  p: 2,
                }}
                spacing={1}
              >
                <Radio
                  checked={el.ID === selectedLocationID}
                  sx={{
                    p: 0,

                    "&.Mui-checked": {
                      color: "text.primary",
                    },
                  }}
                />
                <Typography variant="h4" sx={{ fontWeight: 400 }}>
                  {el.name}
                </Typography>
              </Stack>
            );
          })}
      </Stack>

      <Stack sx={{ alignItems: "flex-end", width: "100%" }}>
        <AppButton
          onClick={closePicker}
          disabled={!selectedLocationID}
          sx={{
            p: "0 27px",
            fontSize: "18px",
            lineHeight: "22px",
            letterSpacing: "0.18px",
            height: "45px",
          }}
        >
          Continue
        </AppButton>
      </Stack>
    </CountDownModal>
  );
};

export default LocationPickerModal;
