import { Modal, Box, SxProps, Theme, Typography } from "@mui/material";
import { useEffect, useState } from "react";

export interface ICountDownModalProps {
  open: boolean;
  children: JSX.Element | JSX.Element[] | any;
  displaySeconds?: number;
  onClose?: () => void;
  sx?: SxProps<Theme>;
}

const CountDownModal: React.FunctionComponent<ICountDownModalProps> = (
  props,
) => {
  const [remainingSeconds, setRemainingSeconds] = useState<number>(0);

  useEffect(() => {
    if (props.open && props.displaySeconds) {
      setRemainingSeconds(props.displaySeconds);

      let intervalID = setInterval(() => {
        setRemainingSeconds((prev) => {
          const newVal = prev - 1;

          if (newVal === 0) {
            props.onClose?.();
            if (intervalID) {
              clearInterval(intervalID);
            }
          }
          return newVal;
        });
      }, 1000);

      return () => {
        if (intervalID) {
          clearInterval(intervalID);
        }
      };
    } else {
      setRemainingSeconds(0);
      return;
    }
  }, [props.open, props.displaySeconds]);

  return (
    <Modal
      open={props.open}
      onClose={() => {
        if (props.onClose) {
          props.onClose();
        }
      }}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          borderRadius: "15px",
          bgcolor: "background.paper",
          outline: "none",
          transform: "translate(-50%, -50%)",
          pt: 5,
          pb: 2,
          px: "120px",
          width: "fit-content",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          textAlign: "center",

          ...props.sx,
        }}
      >
        {props.children}

        {!!remainingSeconds && (
          <Typography sx={{ color: "text.secondary", mt: 1 }}>
            This message will automatically close in{" "}
            <Typography sx={{ fontWeight: "bold", display: "inline" }}>
              {remainingSeconds} second{remainingSeconds !== 1 ? "s" : ""}.
            </Typography>
          </Typography>
        )}
      </Box>
    </Modal>
  );
};

export default CountDownModal;
