import { useState, useContext, useEffect } from "react";

import { IStoreLocation } from "@alex/types";

import { AppContext } from "@/context/AppContext";
import { Modals } from "@/features/phoneNumberCapture/modals/types";
import {
  setLocationID as reducerSetter,
  setFocusedModal,
} from "@/reducers/appReducer";
import {
  getStoredLocationID,
  storeLocationID,
} from "@/services/locationStorage";

export const useConfigureLocation = (): {
  selectedLocationID?: string;
  setLocationID: (selectedID: string) => void;
  isPickerOpen: boolean;
  closePicker: () => void;
  locations: IStoreLocation[];
} => {
  const appContext = useContext(AppContext);

  const [locations, setLocations] = useState<IStoreLocation[]>([]);

  useEffect(() => {
    async function load() {
      const cachedLocation = getStoredLocationID();

      if (cachedLocation) {
        appContext?.dispatch(reducerSetter(cachedLocation));
        appContext?.dispatch(setFocusedModal(undefined));
      }

      try {
        const apiRes = await appContext?.api.get<IStoreLocation[]>(
          "/api/s/instore-capture/locations",
        );

        if (apiRes) {
          setLocations(apiRes);
        } else {
          alert("No locations available.");
        }
      } catch (err) {
        console.error(err);
      }
    }

    load();
  }, []);

  return {
    selectedLocationID: appContext?.state.location.selectedLocationID,
    setLocationID: (selectedID: string) => {
      storeLocationID(selectedID);
      appContext?.dispatch(reducerSetter(selectedID));
    },
    isPickerOpen: appContext?.state.ui.focusedModal === Modals.LocationPicker,
    closePicker: () => {
      appContext?.dispatch(setFocusedModal(undefined));
    },
    locations,
  };
};
