import { useEffect, useState } from "react";
import { Button, Stack, Typography, Grid } from "@mui/material";

import KeyParent from "./KeyParent";

interface INumberKeyProps {
  value: string;
  onClick: () => void;
}

const NumberKey: React.FunctionComponent<INumberKeyProps> = (props) => {
  return (
    <KeyParent onClick={props.onClick}>
      <Typography
        sx={{
          color: "text.primary",
          userSelect: "none",
          fontSize: "24px",
        }}
      >
        {props.value}
      </Typography>
    </KeyParent>
  );
};

export default NumberKey;
