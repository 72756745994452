import { Box, IconButton, Stack, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

import AppButton from "@/components/buttons/AppButton";
import CountDownModal, {
  ICountDownModalProps,
} from "@/components/modals/CountDownModal";
import StarsDisplay from "./StarsDisplay";
import { useContext, useEffect, useState } from "react";
import RewardDisplay from "./RewardDisplay";
import CloseButton from "@/components/buttons/CloseButton";
import { AppContext } from "@/context/AppContext";
import { resetState, setFocusedModal } from "@/reducers/appReducer";
import { Modals } from "../types";

interface IConfirmationModalProps {}

const ConfirmationModal: React.FunctionComponent<IConfirmationModalProps> = (
  props,
) => {
  const [receivedReward, setReceivedReward] = useState(false);
  const appContext = useContext(AppContext);

  const isOpen = appContext?.state.ui.focusedModal === Modals.Confirmation;
  const logoImageUrl = appContext?.state.ui.config?.logoImageUrl;
  const numStarsEarned = appContext?.state.numStarsEarned;

  function closeModal() {
    appContext?.dispatch(setFocusedModal(undefined));
  }

  useEffect(() => {
    if (numStarsEarned) {
      setReceivedReward(numStarsEarned % 10 === 0);
    } else {
      setReceivedReward(false);
    }
  }, [numStarsEarned]);

  return (
    <CountDownModal
      open={isOpen}
      onClose={receivedReward ? undefined : closeModal}
      displaySeconds={receivedReward ? undefined : 30}
      sx={{ width: "85vw" }}
    >
      <Box sx={{ height: "100px" }}>
        <img
          height={"100%"}
          src={logoImageUrl}
          style={{ borderRadius: "5px" }}
        />
      </Box>

      <Stack sx={{ my: 6 }}>
        {receivedReward ? (
          <>
            <RewardDisplay />
            <IconButton
              onClick={closeModal}
              sx={{
                width: "fit-content",
                position: "absolute",
                bottom: 0,
                left: 0,
                bgcolor: "grey.200",
              }}
            >
              <CloseIcon sx={{ color: "text.secondary" }} />
            </IconButton>
          </>
        ) : (
          <Stack sx={{ alignItems: "center" }}>
            <StarsDisplay numStars={numStarsEarned || 0} />
            <CloseButton onClose={closeModal} sx={{ mt: 6 }} />
          </Stack>
        )}
      </Stack>

      <Typography variant="h6" sx={{ color: "text.secondary" }}>
        Make sure to enter your phone number
        <Typography
          variant="inherit"
          sx={{
            color: "text.primary",
            display: "inline",
            fontWeight: "bold",
          }}
        >
          {" "}
          each visit{" "}
        </Typography>
        to get more stars!
      </Typography>
    </CountDownModal>
  );
};

export default ConfirmationModal;
