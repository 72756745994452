import { useEffect, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import jwt_decode from "jwt-decode";

import config from "@/app/config";
import { clearStoredLocationID } from "@/services/locationStorage";

export interface AuthState {
  isLoggedIn?: boolean;
  apiKey?: string;
  getApiKey: (forceRefresh?: boolean) => Promise<string>;
  logout: () => void;
}

const useAppAuth = (): AuthState => {
  const [apiKey, setApiKey] = useState("");
  const {
    isAuthenticated,
    isLoading,
    user,
    getAccessTokenSilently,
    loginWithRedirect,
    logout,
  } = useAuth0();

  async function refreshApiKey(): Promise<string> {
    try {
      const accessToken = await getAccessTokenSilently({
        authorizationParams: {
          audience: config.auth0.audience,
        },
      });

      if (accessToken) {
        setApiKey(accessToken);
        return accessToken;
      } else {
        loginWithRedirect();
      }
    } catch (e) {
      loginWithRedirect();
    }

    return "";
  }

  useEffect(() => {
    refreshApiKey();
  }, [getAccessTokenSilently, user?.sub]);

  function appLogout() {
    clearStoredLocationID();

    logout({
      logoutParams: {
        returnTo: config.auth0.logoutRedirectURI,
      },
    });
  }

  async function getApiKey(forceRefresh = false): Promise<string> {
    if (!apiKey || forceRefresh) {
      return await refreshApiKey();
    }

    const decodedToken = jwt_decode(apiKey) as { exp: number };

    if (new Date(decodedToken.exp * 1000) < new Date()) {
      return await refreshApiKey();
    }

    return apiKey;
  }

  return { isLoggedIn: isAuthenticated, apiKey, getApiKey, logout: appLogout };
};

export default useAppAuth;
