export const config = {
  api: {
    baseURL: process.env.REACT_APP_API_BASE_URL || "",
  },
  cdn: {
    baseURL: process.env.REACT_APP_CDN_BASE_URL || "",
  },
  auth0: {
    domain: process.env.REACT_APP_AUTH0_DOMAIN || "",
    clientID: process.env.REACT_APP_AUTH0_CLIENT_ID || "",
    audience: process.env.REACT_APP_AUTH0_AUDIENCE || "",
    scope: process.env.REACT_APP_AUTH0_SCOPE || "",
    loginRedirectURI: process.env.REACT_APP_AUTH0_LOGIN_REDIRECT_URI || "",
    logoutRedirectURI: process.env.REACT_APP_AUTH0_LOGOUT_REDIRECT_URI || "",
  },
  app: {
    locationIDKey: "LOCATION_ID",
    heartbeat: {
      interval:
        parseInt(process.env.REACT_APP_HEARTBEAT_INTERVAL || "") ||
        1000 * 60 * 5,
    },
  },
};

export default config;
