import { SxProps, Theme } from "@mui/material";
import AppButton from "./AppButton";

interface ICloseButtonProps {
  onClose: () => void;
  sx?: SxProps<Theme>;
}

const CloseButton: React.FC<ICloseButtonProps> = (props) => {
  return (
    <AppButton
      onClick={props.onClose}
      sx={{
        p: "0 27px",
        fontSize: "18px",
        lineHeight: "22px",
        letterSpacing: "0.18px",
        height: "45px",
        ...props.sx,
      }}
    >
      Close
    </AppButton>
  );
};

export default CloseButton;
