import { useContext, useEffect } from "react";
import { BrowserRouter } from "react-router-dom";
import { StyledEngineProvider, CssBaseline } from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";

import { IInstoreCaptureUIConfig } from "@alex/types";

import getTheme from "./app/themes";
import { setFocusedModal, setUIConfig } from "./reducers/appReducer";
import { getStoredLocationID } from "./services/locationStorage";
import { AppContext } from "./context/AppContext";
import PhoneNumberCapture from "./features/phoneNumberCapture";
import { Modals } from "./features/phoneNumberCapture/modals/types";
import { useHeartbeat } from "./hooks/useHeartbeat";

function App() {
  const myTheme = getTheme();

  const appContext = useContext(AppContext);
  const { startPing, stopPing } = useHeartbeat();

  const isLoggedIn = appContext?.auth?.isLoggedIn;

  useEffect(() => {
    // Load UI config
    appContext?.api
      ?.get<IInstoreCaptureUIConfig>("/api/s/instore-capture/ui-config")
      .then((res) => {
        appContext?.dispatch(setUIConfig(res));
      });

    const selectedLocationID = getStoredLocationID();
    if (!selectedLocationID) {
      appContext?.dispatch(setFocusedModal(Modals.LocationPicker));
    }

    startPing();

    return () => {
      stopPing();
    };
  }, [appContext?.dispatch]);

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={myTheme}>
        <CssBaseline />
        {isLoggedIn && (
          <BrowserRouter>
            <PhoneNumberCapture />
          </BrowserRouter>
        )}
      </ThemeProvider>
    </StyledEngineProvider>
  );
}

export default App;
