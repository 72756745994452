import ConfirmationModal from "./confirmation";
import InvalidPhoneNumberModal from "./invalidPhoneNumber";
import LocationPickerModal from "./locationPicker";
import RateLimitModal from "./rateLimit";

const Modals: React.FC = () => {
  return (
    <>
      <ConfirmationModal />
      <InvalidPhoneNumberModal />
      <LocationPickerModal />
      <RateLimitModal />
    </>
  );
};

export default Modals;
