import { Stack, Typography } from "@mui/material";

import { useContext, useState } from "react";
import CountDownModal from "@/components/modals/CountDownModal";
import { AppContext } from "@/context/AppContext";
import LogoutButton from "./LogoutButton";
import CancelButton from "./CancelButton";

interface ILogoutPopOutProps {
  open: boolean;
  onClose: () => void;
}

const LogoutPopOut: React.FC<ILogoutPopOutProps> = (props) => {
  const [showModal, setShowModal] = useState(false);

  const appContext = useContext(AppContext);
  const companyName = appContext?.state.ui.config?.businessName;

  return (
    <Stack spacing={2}>
      {props.open && (
        <>
          <LogoutButton
            sx={{ width: "120px" }}
            onClick={(e) => {
              e.stopPropagation();
              props.onClose();
              setShowModal(true);
            }}
          />

          <CancelButton
            sx={{ width: "120px" }}
            onClick={(e) => {
              e.stopPropagation();
              props.onClose();
            }}
          />
        </>
      )}

      <CountDownModal
        open={showModal}
        onClose={() => setShowModal(false)}
        sx={{ px: "30px", width: "30vw" }}
      >
        <Typography variant="h4" sx={{ whiteSpace: "pre-line", mb: 4 }}>
          Are you sure you want {"\n"}to log out?
        </Typography>
        <Typography
          sx={{
            color: "text.secondary",
            fontSize: 16,
            mb: 4,
            textAlign: "start",
          }}
        >
          This will make the rewards app unavailable to use until someone with a
          valid account logs back in again.
          <Typography
            sx={{
              fontSize: "inherit",
              fontWeight: 600,
              color: "text.primary",
              display: "inline",
              ml: "0.2rem",
            }}
          >
            Do not log out if you are not an employee of {companyName}.
          </Typography>
        </Typography>

        <LogoutButton
          onClick={(e) => {
            e.stopPropagation();
            appContext?.auth?.logout?.();
          }}
          hideIcon
          sx={{ width: "100%", mb: 2 }}
        />

        <CancelButton
          onClick={(e) => {
            e.stopPropagation();
            setShowModal(false);
          }}
          hideIcon
          sx={{ width: "100%" }}
        />
      </CountDownModal>
    </Stack>
  );
};

export default LogoutPopOut;
