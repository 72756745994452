import { useEffect, useState } from "react";
import { Stack, SxProps, Theme } from "@mui/material";

import Instructions from "./instructions";
import NumberInput from "./numberInput";
import LogoutPopOut from "./logoutPopOut";

interface IBodyProps {
  sx?: SxProps<Theme>;
}

const Body: React.FC<IBodyProps> = (props) => {
  const [showLogoutPopOut, setShowLogoutPopOut] = useState(false);

  useEffect(() => {
    if (showLogoutPopOut) {
      setTimeout(() => {
        setShowLogoutPopOut(false);
      }, 5000);
    }
  }, [showLogoutPopOut]);

  return (
    <Stack direction="row" sx={{ alignItems: "center", ...props.sx }}>
      <Stack
        onClick={() => setShowLogoutPopOut((prev) => !prev)}
        sx={{
          width: showLogoutPopOut ? "15%" : "5%",
          height: "100%",
          justifyContent: "center",
        }}
      >
        <LogoutPopOut
          open={showLogoutPopOut}
          onClose={() => setShowLogoutPopOut(false)}
        />
      </Stack>

      <Instructions sx={{ width: showLogoutPopOut ? "40%" : "50%" }} />

      <NumberInput sx={{ width: "45%" }} />
    </Stack>
  );
};

export default Body;
