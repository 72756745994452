import { createTheme } from "@mui/material/styles";
import getThemeTypography from "./typography";

declare module "@mui/material/styles" {
  interface Theme {
    gradient: {
      lightest: string;
      lighter: string;
      left: string;
      right: string;
    };
  }

  interface ThemeOptions {
    gradient?: {
      left?: string;
      right?: string;
    };
  }
}

export const colors = {
  grey: {
    50: "#fff",
    100: "#f5f7fa",
    200: "#f6f6f6",
    300: "#EAEAEA",
    400: "#e0e0e0",
    500: "#989898",
    700: "#626262",
    800: "#292929",
    900: "#000",
  },
  primary: {
    light: "#ddf1f9",
    main: "#5F0EB5",
    dark: "#0736a6",
  },
  secondary: {
    light: "#bf1ad9",
    main: "#7f1190",
    dark: "#1a0ad8",
  },
  secondary_old: {
    light: "#2969ff",
    main: "#094ef4",
    dark: "#0736a6",
  },
  gradient: {
    left: "#1a0ad8",
    right: "#7f1190",
  },
  text: {
    primary: "#000",
    secondary: "#626262",
  },
  success: {
    light: "#edf8ed",
    main: "#00b707",
    dark: "#009e05",
  },
  warning: {
    light: "#f6e419",
    main: "#ebb424",
  },
  error: {
    light: "#faebeb",
    main: "#d40e0d",
    dark: "#ba0b0b",
  },
};

export const getTheme = () => {
  const themes = createTheme({
    palette: {
      primary: colors.primary,
      secondary: colors.secondary,
      success: colors.success,
      warning: colors.warning,
      error: colors.error,
      grey: colors.grey,
      text: colors.text,
    },
    gradient: colors.gradient,
    typography: getThemeTypography(),
  });

  return themes;
};

export default getTheme;
