import { useContext, useEffect, useState } from "react";
import { AxiosError, HttpStatusCode } from "axios";

import config from "@/app/config";
import { AppContext } from "@/context/AppContext";

export const useHeartbeat = (): {
  startPing: () => void;
  stopPing: () => void;
} => {
  const [pingEnabled, setPingEnabled] = useState(false);

  const { interval: pingInterval } = config.app.heartbeat;

  const appContext = useContext(AppContext);
  const { selectedLocationID } = appContext?.state.location || {};
  const { logout } = appContext?.auth || {};

  useEffect(() => {
    if (pingEnabled && selectedLocationID) {
      let doForceRefresh = false;

      console.log({ pingInterval });

      const intervalID = setInterval(async () => {
        try {
          await appContext?.api.post(
            "/api/s/instore-capture/heartbeat",
            {
              locationID: selectedLocationID,
            },
            doForceRefresh,
          );

          doForceRefresh = false;
        } catch (e: any) {
          if (e instanceof AxiosError) {
            const statusCode = e.response?.status;
            const errorMsg = e.response?.data?.errorMsg;

            if (errorMsg === "invalid locationID") {
              alert("Invalid locationID");
              logout?.();
            } else if (statusCode === HttpStatusCode.Unauthorized) {
              doForceRefresh = true;
            }
          }

          console.error(e);
        }
      }, pingInterval);

      return () => {
        if (intervalID) {
          clearInterval(intervalID);
        }
      };
    } else {
      return;
    }
  }, [pingEnabled, selectedLocationID]);

  async function start() {
    setPingEnabled(true);
  }

  async function stop() {
    setPingEnabled(false);
  }

  return { startPing: start, stopPing: stop };
};
