import { useEffect, useState } from "react";
import StarIcon from "@mui/icons-material/Star";

interface ISingleStarProps {
  isColored: boolean;
  index: number;
}

const SingleStar: React.FC<ISingleStarProps> = (props) => {
  const [useColor, setUseColor] = useState(false);

  useEffect(() => {
    if (props.isColored) {
      const timeoutID = setTimeout(() => {
        setUseColor(true);
      }, 50 * props.index);

      return () => {
        clearTimeout(timeoutID);
      };
    }

    return;
  }, [props.isColored]);

  return (
    <>
      <svg width={0} height={0}>
        <linearGradient id="linearColors" x1={0.5} y1={0.5} x2={1} y2={0}>
          <stop offset={0} stopColor={"#F2BD00"} />
          <stop offset={1} stopColor={"#F2BD00"} />
        </linearGradient>
      </svg>
      <StarIcon
        sx={(theme) => ({
          fontSize: 60,
          fill: useColor ? "url(#linearColors)" : theme.palette.grey[200],
        })}
      />
    </>
  );
};

export default SingleStar;
