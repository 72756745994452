import { Box, Stack, SxProps, Theme } from "@mui/material";
import PoweredByAlex from "./PoweredByAlex";
import SubmitButton from "./SubmitButton";

interface IFooterProps {
  sx?: SxProps<Theme>;
}

const Footer: React.FC<IFooterProps> = (props) => {
  return (
    <Stack
      direction="row"
      sx={{
        alignItems: "center",
        justifyContent: "space-between",
        bgcolor: "grey.200",
        ...props.sx,
      }}
    >
      <PoweredByAlex sx={{ width: "20%" }} />

      <SubmitButton />

      <Box sx={{ width: "20%" }} />
    </Stack>
  );
};

export default Footer;
