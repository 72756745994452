import { useContext } from "react";
import { Stack, SxProps, Theme, Typography } from "@mui/material";

import { AppContext } from "@/context/AppContext";

interface IHeaderProps {
  sx?: SxProps<Theme>;
}

const Header: React.FC<IHeaderProps> = (props) => {
  const appContext = useContext(AppContext);

  const { logoImageUrl, businessName } = appContext?.state.ui.config || {};

  return (
    <Stack
      direction="row"
      sx={{
        alignItems: "center",
        justifyContent: "space-between",
        height: "100%",
        borderBottom: (theme) => `1px solid ${theme.palette.grey[200]}`,

        ...props.sx,
      }}
    >
      <img height={"95%"} src={logoImageUrl} style={{ borderRadius: "5px" }} />

      {businessName && (
        <Stack direction="row" spacing={2} sx={{ alignItems: "center" }}>
          <Typography variant="h2" sx={{ fontWeight: 300 }}>
            <Typography
              variant="inherit"
              sx={{ display: "inline", fontWeight: 900 }}
            >
              {`${businessName} `}
            </Typography>
            Rewards.
          </Typography>
        </Stack>
      )}
    </Stack>
  );
};

export default Header;
