import { useContext } from "react";
import { isValidPhoneNumber } from "libphonenumber-js";
import { AxiosError, HttpStatusCode } from "axios";

import { IInstoreCaptureResponse } from "@alex/types";

import { AppContext } from "@/context/AppContext";
import {
  setShowConsentHint,
  setFocusedModal,
  setNumStarsEarned,
  setPhoneNumber,
  setConsentStatus,
} from "@/reducers/appReducer";
import { Modals } from "@/features/phoneNumberCapture/modals/types";

export const useAddVisit = (): {
  submit: () => void;
} => {
  const appContext = useContext(AppContext);

  const { phoneNumber, hasProvidedConsent } = appContext?.state.userInput || {};
  const { selectedLocationID } = appContext?.state.location || {};
  const { logout } = appContext?.auth || {};

  function validateCapture(): boolean {
    if (!hasProvidedConsent) {
      appContext?.dispatch(setShowConsentHint(true));
      return false;
    }

    if (!phoneNumber || !isValidPhoneNumber(phoneNumber, "US")) {
      appContext?.dispatch(setFocusedModal(Modals.InvalidPhoneNumber));
      return false;
    }

    return true;
  }

  async function submit(isRetry = false) {
    const isValid = validateCapture();

    if (isValid) {
      try {
        const apiRes = await appContext?.api.post<IInstoreCaptureResponse>(
          "/api/s/instore-capture/add-visit",
          {
            phoneNumber,
            locationID: selectedLocationID,
          },
        );

        appContext?.dispatch(setNumStarsEarned(apiRes?.numVisits));
      } catch (e) {
        if (e instanceof AxiosError) {
          const statusCode = e.response?.status;
          const errorMsg = e.response?.data?.errorMsg;

          if (errorMsg === "invalid locationID") {
            alert("Invalid locationID");
            logout?.();
          } else if (statusCode === HttpStatusCode.TooManyRequests) {
            console.log({ e });
            appContext?.dispatch(setFocusedModal(Modals.RateLimit));
          } else if (statusCode === HttpStatusCode.Unauthorized) {
            if (isRetry) {
              logout?.();
            } else {
              await submit(true);
            }
          }
        }

        console.error(e);
      } finally {
        appContext?.dispatch(setPhoneNumber(""));
        appContext?.dispatch(setConsentStatus(false));
      }
    }
  }

  return {
    submit,
  };
};
