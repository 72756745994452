import { Typography } from "@mui/material";
import { AppContext } from "@/context/AppContext";
import { useContext } from "react";
import { Modals } from "../types";
import CountDownModal from "@/components/modals/CountDownModal";
import { setFocusedModal } from "@/reducers/appReducer";
import CloseButton from "@/components/buttons/CloseButton";

interface IRateLimitModalProps {}

const RateLimitModal: React.FC<IRateLimitModalProps> = (props) => {
  const appContext = useContext(AppContext);

  const { focusedModal } = appContext?.state.ui || {};

  const isOpen = focusedModal === Modals.RateLimit;

  function closeModal() {
    appContext?.dispatch(setFocusedModal(undefined));
  }

  return (
    <CountDownModal
      open={isOpen}
      displaySeconds={20}
      onClose={closeModal}
      sx={{ width: "70vw" }}
    >
      <Typography variant="h2" sx={{ mb: 3, whiteSpace: "pre-line" }}>
        Sorry, you may only enter your number once every 3 hours.
      </Typography>

      <Typography variant="h5" sx={{ color: "text.secondary", mb: 3 }}>
        Feel free to come back and enter your phone number again in 3 hours.
      </Typography>

      <CloseButton onClose={closeModal} sx={{ my: 4 }} />
    </CountDownModal>
  );
};

export default RateLimitModal;
