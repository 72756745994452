import KeyParent from "./KeyParent";
import BackspaceIcon from "@mui/icons-material/Backspace";

interface IDeleteKeyProps {
  onClick: () => void;
}

const DeleteKey: React.FunctionComponent<IDeleteKeyProps> = (props) => {
  return (
    <KeyParent onClick={props.onClick}>
      <BackspaceIcon sx={{ fontSize: 30, color: "grey.500" }} />
    </KeyParent>
  );
};

export default DeleteKey;
