import { useContext } from "react";
import { Typography } from "@mui/material";

import CountDownModal from "@/components/modals/CountDownModal";
import { AppContext } from "@/context/AppContext";
import { Modals } from "../types";
import { setFocusedModal } from "@/reducers/appReducer";
import CloseButton from "@/components/buttons/CloseButton";

interface IInvalidPhoneNumberModalProps {}

const InvalidPhoneNumberModal: React.FC<IInvalidPhoneNumberModalProps> = (
  props,
) => {
  const appContext = useContext(AppContext);

  const { focusedModal } = appContext?.state.ui || {};

  const isOpen = focusedModal === Modals.InvalidPhoneNumber;

  function closeModal() {
    appContext?.dispatch(setFocusedModal(undefined));
  }

  return (
    <CountDownModal
      open={isOpen}
      displaySeconds={20}
      onClose={closeModal}
      sx={{ width: "70vw" }}
    >
      <Typography variant="h2" sx={{ mb: 3, whiteSpace: "pre-line" }}>
        Uh-oh. Looks like this isn’t a {"\n"} valid phone number.
      </Typography>

      <Typography variant="h5" sx={{ fontWeight: "bold", mb: 1 }}>
        Please try entering your phone again.
      </Typography>

      <Typography variant="h5" sx={{ color: "text.secondary", mb: 3 }}>
        The number must be able to receive texts.
      </Typography>

      <Typography variant="h6" sx={{ color: "text.secondary" }}>
        International numbers cannot be accepted at this time.
      </Typography>

      <CloseButton onClose={closeModal} sx={{ my: 5 }} />
    </CountDownModal>
  );
};

export default InvalidPhoneNumberModal;
