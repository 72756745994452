import { SxProps, Theme, TextField } from "@mui/material";
import InputMask from "react-input-mask";

interface INumberDisplayProps {
  value?: string;
  sx?: SxProps<Theme>;
}

const NumberDisplay: React.FunctionComponent<INumberDisplayProps> = (props) => {
  return (
    <InputMask mask="(999) 999 - 9999" value={props.value || ""} alwaysShowMask>
      {/* @ts-ignore */}
      {(inputProps: { value: string }) => (
        <TextField
          {...inputProps}
          sx={{
            "& input": {
              fontSize: "2.25rem",
            },
            "& .MuiOutlinedInput-notchedOutline": {
              borderWidth: 0,
            },
            "& .MuiOutlinedInput-input": {
              py: "5px",
              textAlign: "center",
              letterSpacing: "3px",
            },
            bgcolor: "grey.200",
            borderRadius: "25px",
          }}
        />
      )}
    </InputMask>
  );
};

export default NumberDisplay;
