import config from "@/app/config";

export function storeLocationID(locationID: string) {
  localStorage.setItem(config.app.locationIDKey, locationID);
}

export function getStoredLocationID(): string | null {
  const cached = localStorage.getItem(config.app.locationIDKey);

  return cached;
}

export function clearStoredLocationID() {
  localStorage.removeItem(config.app.locationIDKey);
}
