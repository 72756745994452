import { Stack, SxProps, Theme, Typography } from "@mui/material";

interface IPoweredByAlexProps {
  sx?: SxProps<Theme>;
}

const PoweredByAlex: React.FC<IPoweredByAlexProps> = (props) => {
  return (
    <Stack
      direction="row"
      sx={{
        height: "100%",
        alignItems: "flex-end",

        ...props.sx,
      }}
    >
      <Stack direction="row" sx={{ alignItems: "center" }}>
        <Typography
          sx={{ fontSize: "0.8rem", color: "text.secondary", mr: 0.5 }}
        >
          Powered by{" "}
        </Typography>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="55"
          height="10"
          viewBox="0 0 55 10"
          fill="none"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M5.77222 0L11.5444 10H0L5.77222 0ZM8.12778 8.18889L8.26667 7.94445L5.91667 3.87222H5.63333L3.28333 7.94445L3.42222 8.18889H8.12778Z"
            fill="#626262"
          />
          <path
            d="M18.4975 8.60819L18.554 8.66468C18.554 8.66468 19.1301 8.22636 19.582 8.22636H25.244V9.99997H16.9883V0.110677H18.9358V7.58018C18.9358 7.88745 18.8613 8.18117 18.4975 8.60819Z"
            fill="#626262"
          />
          <path
            d="M32.1916 8.60142L32.2503 8.66016V8.66242C32.6751 8.30092 32.9666 8.22636 33.2738 8.22636H38.9426V9.99997H30.7094V0.112939H38.9675V1.85718H32.6548L32.6277 1.88429V4.24308L32.6548 4.27019H38.2196V5.84046H32.6548L32.6277 5.86757V7.57792C32.6277 7.88519 32.5531 8.17665 32.1916 8.60142Z"
            fill="#626262"
          />
          <path
            d="M50.7704 4.95252V4.98867L55.0384 9.99997H52.5825L49.6249 6.52731H49.4306L46.4731 9.99997H44.0172L48.2851 4.98867V4.95252L44.1618 0.110677H46.6177L49.4306 3.41389H49.6249L52.4379 0.110677H54.8938L50.7704 4.95252Z"
            fill="#626262"
          />
        </svg>
      </Stack>
    </Stack>
  );
};

export default PoweredByAlex;
