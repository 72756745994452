import {
  Box,
  Checkbox,
  FormControlLabel,
  Stack,
  Typography,
} from "@mui/material";

interface IConsentConfirmationProps {
  businessName?: string;
  showHint: boolean;
  hasProvidedConsent?: boolean;
  onChange: (newVal: boolean) => void;
}

const ConsentConfirmation: React.FC<IConsentConfirmationProps> = (props) => {
  return (
    <Stack
      onClick={() => props.onChange(!props.hasProvidedConsent)}
      direction="row"
      sx={{ width: "100%", userSelect: "none", py: 3 }}
    >
      <Box sx={{ width: "40px" }}>
        <Stack
          sx={{
            height: "27px",
            width: "27px",
            border: "2px solid black",
            alignItems: "center",
            justifyContent: "center",
            borderRadius: "6px",
            position: "relative",
            mr: 1,
          }}
        >
          {props.showHint && (
            <Stack
              sx={{ position: "absolute", bottom: 27, alignItems: "center" }}
            >
              <Box
                sx={{
                  bgcolor: "black",
                  color: "white",
                  p: 2,
                  borderRadius: "6px",
                  width: "250px",
                }}
              >
                <Typography variant="h6">
                  You must check the box to submit your phone number.
                </Typography>
              </Box>

              <Box
                sx={{
                  width: 0,
                  height: 0,
                  borderLeft: "10px solid transparent",
                  borderRight: "10px solid transparent",
                  borderBottom: "10px solid black",
                  transform: "rotate(180deg)",
                }}
              />
            </Stack>
          )}

          {props.hasProvidedConsent && (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="18"
              viewBox="0 0 17 16"
              fill="none"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M1.48435 6.93564C1.0806 7.31247 1.05878 7.94526 1.43561 8.34901L6.87846 14.1806L15.6037 3.6376C15.9558 3.21212 15.8964 2.58175 15.4709 2.22963C15.0454 1.87751 14.4151 1.93698 14.0629 2.36246L6.78821 11.1528L2.89772 6.98438C2.52089 6.58063 1.8881 6.55881 1.48435 6.93564Z"
                fill="black"
              />
            </svg>
          )}
        </Stack>
      </Box>

      <Stack sx={{ width: "calc(100% - 40px)" }}>
        <Typography sx={{ color: "text.secondary" }}>
          <Typography
            sx={{
              fontWeight: "bold",
              color: "text.primary",
              display: "inline",
              mr: "0.2rem",
            }}
          >
            I agree
          </Typography>
          to receive marketing messages from
          <Typography
            sx={{
              fontWeight: "bold",
              color: "text.primary",
              display: "inline",
              mx: "0.2rem",
            }}
          >
            {props.businessName}
          </Typography>
          at the phone number provided above. Reply HELP for help and STOP to
          cancel. Message frequency varies. Message and data rates may apply.
        </Typography>
      </Stack>
    </Stack>
  );
};

export default ConsentConfirmation;
