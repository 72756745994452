import LogoutIcon from "@mui/icons-material/Logout";
import { Typography } from "@mui/material";

import AppButton, { IAppButtonProps } from "@/components/buttons/AppButton";

interface ILogoutButtonProps extends Omit<IAppButtonProps, "children"> {
  hideIcon?: boolean;
}

const LogoutButton: React.FC<ILogoutButtonProps> = (props) => {
  return (
    <AppButton
      onClick={(e) => {
        props.onClick(e);
      }}
      sx={{ width: "fit-content", px: 3, py: 1.5, ...props.sx }}
    >
      {!props.hideIcon && <LogoutIcon sx={{ fontSize: 16, mr: 1 }} />}

      <Typography sx={{ fontSize: 16, letterSpacing: "0.18px" }}>
        Logout
      </Typography>
    </AppButton>
  );
};

export default LogoutButton;
