import { Button, SxProps, Theme } from "@mui/material";

export interface IAppButtonProps {
  onClick: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  children: any;
  disabled?: boolean;
  sx?: SxProps<Theme>;
}

const AppButton: React.FC<IAppButtonProps> = (props) => {
  return (
    <Button
      onClick={(e) => props.onClick(e)}
      variant="contained"
      sx={{
        bgcolor: "black",
        borderRadius: "100px",
        "&:hover": {
          bgcolor: "black",
        },
        ...props.sx,
      }}
      disableElevation
      disableRipple
      disabled={props.disabled}
    >
      {props.children}
    </Button>
  );
};

export default AppButton;
