import { Stack } from "@mui/material";

import Header from "./header";
import Body from "./body";
import Footer from "./footer";
import Modals from "./modals";

interface IInstoreCaptureProps {}

const InstoreCapture: React.FunctionComponent<IInstoreCaptureProps> = () => {
  return (
    <Stack sx={{ height: "100vh" }}>
      <Header sx={{ height: "10%", p: 1, mx: 5 }} />

      <Body sx={{ height: "78%", p: 1, mr: 5 }} />

      <Footer sx={{ height: "12%", py: 1, px: 6 }} />

      <Modals />
    </Stack>
  );
};

export default InstoreCapture;
