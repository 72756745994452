import { Button, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

import { IAppButtonProps } from "@/components/buttons/AppButton";

interface ICancelButtonProps extends Omit<IAppButtonProps, "children"> {
  hideIcon?: boolean;
}

const CancelButton: React.FC<ICancelButtonProps> = (props) => {
  return (
    <Button
      onClick={(e) => props.onClick(e)}
      variant="contained"
      sx={{
        bgcolor: "grey.300",
        color: "text.primary",
        borderRadius: "100px",
        "&:hover": {
          bgcolor: "grey.300",
        },
        px: 3,
        py: 1.5,
        width: "100%",
        ...props.sx,
      }}
      disableElevation
      disableRipple
      disabled={props.disabled}
    >
      {!props.hideIcon && <CloseIcon sx={{ fontSize: 16, mr: 1 }} />}

      <Typography sx={{ fontSize: 16, letterSpacing: "0.18px" }}>
        Cancel
      </Typography>
    </Button>
  );
};

export default CancelButton;
