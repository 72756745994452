import { Grid, Stack, SxProps, Theme, Typography } from "@mui/material";

import SingleStar from "./SingleStar";

interface IStarsDisplayProps {
  numStars: number;
  sx?: SxProps<Theme>;
}

const StarsDisplay: React.FC<IStarsDisplayProps> = (props) => {
  const numColoredStars = props.numStars % 10;

  return (
    <Stack sx={{ ...props.sx }}>
      <Typography variant="h1" sx={{ mb: 2, fontWeight: 400 }}>
        🤩 You just earned a star! 🤩
      </Typography>

      <Typography
        variant="h4"
        sx={{ color: "text.secondary", fontWeight: 500, my: 2 }}
      >
        Earn 10 stars and get a reward!
      </Typography>
      <Grid container columns={10} sx={{ px: "10%" }}>
        {Array(10)
          .fill(null)
          .map((el, i) => {
            return (
              <Grid key={i} item xs={2}>
                <SingleStar isColored={i < numColoredStars} index={i} />
              </Grid>
            );
          })}
      </Grid>
    </Stack>
  );
};

export default StarsDisplay;
