import { Stack, SxProps, Theme, Typography } from "@mui/material";

interface IRewardDisplayProps {
  sx?: SxProps<Theme>;
}

const RewardDisplay: React.FC<IRewardDisplayProps> = (props) => {
  return (
    <Stack sx={{ ...props.sx }}>
      <Typography variant="h1" sx={{ mb: 3, fontWeight: 400 }}>
        👏 You just earned a free smoothie! 👏
      </Typography>

      <Typography variant="h4" sx={{ fontWeight: "bold", my: 3 }}>
        Let your cashier know, and they'll ring it up!
      </Typography>
    </Stack>
  );
};

export default RewardDisplay;
