import React, { createContext, useReducer, ReactNode } from "react";
import appReducer, { AppAction } from "@/reducers/appReducer";
import useAppAuth, { AuthState } from "@/hooks/useAppAuth";
import useApi, { ApiState } from "@/hooks/useApi";
import { Modals } from "@/features/phoneNumberCapture/modals/types";

export interface AppState {
  userInput: {
    phoneNumber: string;
    hasProvidedConsent: boolean;
  };
  numStarsEarned?: number;
  ui: {
    config?: {
      title: string;
      subTitle: string;
      businessName: string;
      logoImageUrl: string;
      bodyImageUrl?: string;
    };
    showConsentHint: boolean;
    focusedModal?: Modals;
  };
  location: {
    selectedLocationID?: string;
  };
}

const initialState: AppState = {
  userInput: {
    phoneNumber: "",
    hasProvidedConsent: false,
  },
  ui: {
    showConsentHint: false,
  },
  location: {},
};

interface AppContextType {
  state: AppState;
  dispatch: React.Dispatch<AppAction>;
  auth: AuthState;
  api: ApiState;
}

const AppContext = createContext<AppContextType | undefined>(undefined);

interface AppContextProviderProps {
  children: ReactNode;
}

const AppContextProvider: React.FC<AppContextProviderProps> = ({
  children,
}) => {
  const [state, dispatch] = useReducer(appReducer, initialState);

  const authState = useAppAuth();

  const { get: apiGet, post: apiPost } = useApi(authState.getApiKey);

  return (
    <AppContext.Provider
      value={{
        state,
        dispatch,
        auth: authState,
        api: {
          get: apiGet,
          post: apiPost,
        },
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

export { AppContext, AppContextProvider };
