import { useContext } from "react";
import { Stack, SxProps, Theme } from "@mui/material";

import NumberDisplay from "./NumberDisplay";
import PhoneNumberKeypad from "./keypad";
import { AppContext } from "@/context/AppContext";
import {
  setConsentStatus,
  setPhoneNumber,
  setShowConsentHint,
} from "@/reducers/appReducer";
import ConsentConfirmation from "./ConsentConfirmation";

interface INumberInputProps {
  sx?: SxProps<Theme>;
}

const NumberInput: React.FC<INumberInputProps> = (props) => {
  const appContext = useContext(AppContext);

  const { phoneNumber, hasProvidedConsent } =
    appContext?.state?.userInput || {};
  const { businessName } = appContext?.state.ui.config || {};
  const { showConsentHint } = appContext?.state.ui || {};

  return (
    <Stack sx={{ px: "30px", ...props.sx }}>
      <NumberDisplay value={phoneNumber} />

      <PhoneNumberKeypad
        value={phoneNumber}
        onChange={(newVal: string) => {
          appContext?.dispatch(setPhoneNumber(newVal));
        }}
        sx={{ mt: 4 }}
      />

      <ConsentConfirmation
        businessName={businessName}
        showHint={showConsentHint || false}
        hasProvidedConsent={hasProvidedConsent}
        onChange={(newVal: boolean) => {
          if (newVal) {
            appContext?.dispatch(setShowConsentHint(false));
          }

          appContext?.dispatch(setConsentStatus(newVal));
        }}
      />
    </Stack>
  );
};

export default NumberInput;
